<template>
  <el-drawer title="快速制卡" :visible.sync="visible" direction="rtl" :before-close="handleClose" size="37%">
    <el-button @click="returnCard" class="return-card" type="primary">退卡</el-button>
    <el-table :data="roomData" style="width: 100%" border>
      <el-table-column prop="roomNo" label="房间号"></el-table-column>
      <el-table-column label="住宿时间">
        <template slot-scope="scope">
          <p>{{ scope.row.startTime }}</p>
          <p>{{ scope.row.endTime }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope"><el-button @click="handleCard(scope.row)" type="text">制卡</el-button></template>
      </el-table-column>
    </el-table>
  </el-drawer>
</template>
<script>
  import { getByBrandCode, addRoomCard } from '@/api/pms/atrialState/atrialState'
  import { mapState } from 'vuex'
  import axios from 'axios'
  export default {
    name: 'businessCard',
    props: {
      visible: Boolean,
      roomItem: Object,
    },
    data() {
      return {
        roomData: [],
        formData: {
          realName: '',
          IDNo: '',
          lockType: '',
          roomNo: '',
          startTime: '',
          endTime: '',
        },
        listeningAddress: '',
        listeningPort: 0,
        logParam: {
          bookId: '',
          roomId: '',
          checkinMemberId: '',
          checkinRoomId: '',
        },
      }
    },
    mounted() {
      // 将callbackFun方法绑定到window对象
      window['jQueryCard'] = (data) => {
        this.jQueryCardCallback(data)
      }
      window['jQueryReturnCard'] = (data) => {
        this.jQueryReturnCardCallback(data)
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    watch: {
      hotelInfo(newVal, oldVal) {
        oldVal && newVal.id !== oldVal.id && this.getByBrandCode()
      },
    },
    methods: {
      // 获取房间信息
      getRoomInfo(row) {
        this.formData.roomNo = row.roomNo
        this.formData.startTime = row.checkInTime
        this.formData.endTime = row.endTime
        this.formData.realName = row.checkinMemberList[0].realName
        this.formData.IDNo = row.checkinMemberList[0].certificateNo
        this.roomData = [{ roomNo: this.formData.roomNo, startTime: this.formData.startTime, endTime: this.formData.endTime }]
        this.logParam.bookId = row.id
        this.logParam.roomId = row.roomId
        this.logParam.checkinMemberId = row.checkinMemberList[0].id
        this.logParam.checkinRoomId = row.checkinMemberList[0].checkinRoomId
      },
      // 获取硬件品牌
      getByBrandCode() {
        const params = { hotelId: this.hotelInfo.id }
        getByBrandCode(params).then(({ success, data }) => {
          if (success) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].hardwareType === 'DOOR_LOCK') {
                this.formData.lockType = data[i].brandCode
                this.listeningAddress = data[i].listeningAddress
                this.listeningPort = data[i].listeningPort
              }
            }
          }
        })
      },
      // 关闭抽屉
      handleClose() {
        this.$emit('close', false)
      },
      jQueryCardCallback(data) {
        let message, err
        if (data.ret_code === 0) {
          message = '制卡成功！'
          err = 'success'
        } else {
          message = '制卡失败！'
          err = 'error'
        }
        this.$message({ message, err })
      },
      // 制卡
      handleCard() {
        // lo   /local/lock/operation
        if (this.formData.lockType === 'lo') {
          const url = this.listeningAddress + ':' + this.listeningPort + '/local/lock/operation',
            params = {
              callback: 'jQueryCard',
              sector: 0,
              lock_type: 7,
              lost: 0,
              com: 2,
              dric: 11,
              pub_door: 2,
              card_type: 1,
              lift_data: 2,
              is_share: 0,
              read_type: 13,
              card_name: 2,
              // door:'008806',
              sub_door: 0,
              begin_time: this.formData.startTime.substring(0, 16),
              end_time: this.formData.endTime.substring(0, 16),
              log_type: 'request',
              room_num: this.formData.roomNo,
              _: new Date().getTime(),
              callbackQuery: 'callbackParam',
              callbackName: 'jsonpCallback',
            }
          let arr = ['', '00000', '0000', '000', '00', '0', '']
          if (this.formData.roomNo.length <= 6) {
            params.door = arr[this.formData.roomNo.length] + this.formData.roomNo
          }
          this.$jsonp(url, params).then((res) => {
            // 此处代码不执行
            // 因为会直接调用返回的那个回调函数
          })
        } else {
          const url = this.listeningAddress + ':' + this.listeningPort + '/lock/newRoomCard',params = { ...this.formData }
          axios .post(url, params) .then(({ data }) => {
            let message, err
            if (data.isSuccess) {
              message = '制卡成功！'
              err = 'success'
              this.businessCardLog(data.data)
            } else {
              message = data.msg
              err = 'error'
            }
            this.$message({ message, err })
          }).catch((err) => {
            const message = err.response && err.response.data && err.response.data.message
            this.$message.error(message)
          })
        }
      },
      // 发卡日志
      businessCardLog({ cardNo, roomNo, cardId, state, startTime, endTime }) {
        const map = new Map([[1, 'NORMAL_USE'], [2, 'RETURN_CARD'], [3, 'PIN_CARD']])
        const params = {
          ...this.logParam,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId,
          roomNo,
          carId: cardId,
          carNo: cardNo,
          state: map.get(state),
          startTime,
          endTime,
        }
        addRoomCard(params)
      },
      // 退卡
      returnCard() {
        this.$confirm('确定退卡吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          // lo   /local/lock/operation
          if (this.formData.lockType === 'lo') {
            const url = this.listeningAddress + ':' + this.listeningPort + '/local/lock/operation',
              params = {
                callback: 'jQueryReturnCard',
                sector: 0,
                lock_type: 7,
                max_lift_addr: 1,
                lost: 0,
                com: 2,
                dric: 11,
                pub_door: 2,
                card_type: 1,
                lift_data: 2,
                is_share: 0,
                read_type: 13,
                card_name: 4,
                sub_door: 0,
                log_type: 'request',
                _: new Date().getTime(),
                callbackQuery: 'callbackParam',
                callbackName: 'jsonpCallback',
              }
            let arr = ['', '00000', '0000', '000', '00', '0', '']
            if (this.formData.roomNo.length <= 6) {
              params.door = arr[this.formData.roomNo.length] + this.formData.roomNo
            }
            this.$jsonp(url, params).then((res) => {
              // 此处代码不执行
              // 因为会直接调用返回的那个回调函数
            })
          } else {
            const url = this.listeningAddress + ':' + this.listeningPort + '/lock/resetCard' + `/${this.formData.lockType}`
            axios.post(url).then(({ data }) => {
              let message, type
              if (data.isSuccess) {
                if (data.data) {
                  message = '退卡成功！'
                  type = 'success'
                } else {
                  message = '退卡失败!'
                  type = 'error'
                }
              }
              this.$message({ message, type })
            }).catch((err) => {
              const message = err.response && err.response.data && err.response.data.message
              this.$message.error(message)
            })
          }
        })
      },
      jQueryReturnCardCallback(data) {
        let message, err
        if (data.ret_code === 0) {
          message = '退卡成功！'
          err = 'success'
        } else {
          message = '退卡失败！'
          err = 'error'
        }
        this.$message({ message, err })
      },
    },
  }
</script>
<style scoped lang="scss">
  ::v-deep .el-drawer {
    .return-card {
      margin: 0 0 10px 0;
      float: right;
    }
    .el-drawer__body {
      padding: 0 30px;
    }
  }
</style>
